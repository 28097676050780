<template>
  <main class="member-shop-order-manage">
    <PageTitle title="商城訂單管理" hideBtn />
    <FilterContainer>
      <el-input
        v-model="search.code"
        placeholder="輸入訂單編號"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <MemberSearch :model.sync="search.member" @change="refresh(true)" @clear="refresh(true)" />
      <el-select v-model="search.status" clearable placeholder="選擇訂單狀態" @change="refresh(true)" @clear="refresh(true)">
        <el-option
          v-for="item in orderStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="search.payment" clearable placeholder="選擇付款狀態" @change="refresh(true)" @clear="refresh(true)">
        <el-option
          v-for="item in orderPaymentStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </FilterContainer>
    <MemberShopOrderTable v-loading="loading.table" :tableData="tableData" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, reactive, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import MemberShopOrderTable from './components/MemberShopOrderTable.vue'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import { orderStatusConfig, orderPaymentStatusConfig } from '@/config/memberShop'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import store from '@/store'
import { GetMemberShopOrder, GetMemberShopOrderCount } from '@/api/memberShop'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderManage',
  components: { MemberShopOrderTable, MemberSearch, FilterContainer, PageTitle },
  setup (props) {
    const router = useRouter()
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex } = useTable()
    const shopId = computed(() => store.getters.shop)

    const search = reactive({
      code: null,
      member: null,
      status: null,
      payment: null,
    })

    const getMemberShopOrder = async (payload) => {
      const [res, err] = await GetMemberShopOrder(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getMemberShopOrderCount = async (payload) => {
      const [res, err] = await GetMemberShopOrderCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        MemberId: get(search, 'member.id') || undefined,
        code: search.code || undefined,
        status: search.status || undefined,
        paymentStatus: search.payment || undefined,
      }
      await Promise.allSettled([
        getMemberShopOrder(payload),
        getMemberShopOrderCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.value = true
      await getTableData()
      loading.value = false
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      tableData,
      tableOptions,
      loading,
      refresh,
      search,
      orderStatusConfig,
      orderPaymentStatusConfig,
      tableDataCount,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
